import * as React from 'react';
import { CreditBucket } from '@bondvet/types/creditLedger';
import useTranslate from 'hooks/useTranslate';
import Select, { SingleValue } from 'react-select';
import { CREDIT_MEMO_NOTE_OPTIONS } from '@bondvet/types/creditLedger/constants';
import type { CreditMemoNoteOption } from '@bondvet/types/creditLedger';
import UpcomingVisitSelect from '../UpcomingVisitSelect';
import styles from './CreditReasonSelect.module.scss';
import { PROMO_CODE_NOTE_PREFIX } from '../../constants';
import addCreditMemoStyles from '../AddCreditMemo/AddCreditMemo.module.scss';
import { UpcomingVisitOption } from '../UpcomingVisitSelect/useUpcomingVisits';
import PromoCodeSelect from '../PromoCodeSelect';

type CreditReasonSelectProps = {
    promoCodeValidation?: boolean;
    setNote: (value: string) => void;
    note: string;
    selectedVisit: SingleValue<UpcomingVisitOption> | null;
    setSelectedVisit: (value: SingleValue<UpcomingVisitOption> | null) => void;
    setAmount: (amount: string) => void;
    clientId: string;
    setPromoCodePatientTag: (value?: string | null) => void;
};

function getCurrentReasonValue(note: string) {
    if (note) {
        const noteValue = note.startsWith(PROMO_CODE_NOTE_PREFIX)
            ? CreditBucket.promoCode
            : note;
        return (
            CREDIT_MEMO_NOTE_OPTIONS.find(
                (option: CreditMemoNoteOption) => option.value === noteValue,
            ) ?? null
        );
    }

    return null;
}

function CreditReasonSelect({
    promoCodeValidation = false,
    setNote,
    note,
    selectedVisit,
    setSelectedVisit,
    setAmount,
    clientId,
    setPromoCodePatientTag,
}: CreditReasonSelectProps): React.ReactElement {
    const translate = useTranslate();
    const [currentValue, setCurrentValue] =
        React.useState<CreditMemoNoteOption | null>(() =>
            getCurrentReasonValue(note),
        );
    const [selectPromoCode, setSelectPromoCode] = React.useState(false);

    // we'll add a React.useEffect here, so we can handle
    // a form reset, which will reset the `note` to `''`
    React.useEffect(() => {
        setCurrentValue(getCurrentReasonValue(note));
    }, [note]);

    React.useEffect(() => {
        setAmount('');
    }, [selectPromoCode, setAmount]);

    const handleReasonSelect = React.useCallback(
        (option: SingleValue<CreditMemoNoteOption>) => {
            setCurrentValue(option);
            const newNote = option?.value ?? '';

            if (newNote === CreditBucket.promoCode) {
                setSelectPromoCode(true);
                setNote(newNote);
            } else {
                setSelectPromoCode(false);
                setNote(newNote);
            }
        },
        [setNote],
    );

    const handleVisitSelect = React.useCallback(
        (visit: SingleValue<UpcomingVisitOption> | null) => {
            setSelectedVisit(visit);
            setNote(
                visit?.appointmentData?.promoCode
                    ? `${PROMO_CODE_NOTE_PREFIX}${visit?.appointmentData?.promoCode}`
                    : CreditBucket.promoCode,
            );
            setAmount('');
        },
        [setAmount, setNote, setSelectedVisit],
    );

    return (
        <>
            <div className={addCreditMemoStyles.section}>
                {translate(
                    'vetspireExtension.clientDetails.credits.addCredits.reason',
                )}
            </div>

            <Select<CreditMemoNoteOption>
                name="note"
                className={styles.lookup}
                classNamePrefix="creditreason-select"
                value={currentValue}
                onChange={handleReasonSelect}
                options={CREDIT_MEMO_NOTE_OPTIONS}
                required
                placeholder={
                    <div className={styles.placeholder}>
                        Select Reason here...
                    </div>
                }
            />

            {promoCodeValidation && selectPromoCode && (
                <UpcomingVisitSelect
                    skip={!selectPromoCode}
                    visit={selectedVisit}
                    selectVisit={handleVisitSelect}
                    clientId={clientId}
                />
            )}

            {selectPromoCode && (
                <PromoCodeSelect
                    note={note}
                    setNote={setNote}
                    selectedVisit={selectedVisit}
                    skipQuery={!!promoCodeValidation && !selectedVisit?.id}
                    setAmount={setAmount}
                    setPromoCodePatientTag={setPromoCodePatientTag}
                />
            )}
        </>
    );
}

export default CreditReasonSelect;
